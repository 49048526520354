import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link,
  TablePagination, Box, Collapse, IconButton, Typography, Grid, Chip, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary,
  AccordionDetails, Modal, Backdrop, Fade, CircularProgress
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  ErrorOutline as ErrorOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Code as CodeIcon,
  Image as ImageIcon,
  VideoLibrary as VideoLibraryIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  ZoomIn as ZoomInIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Call, CallAction } from '../types/Call';
import { Summary } from '../types';

interface CallTableProps {
  calls: Call[];
  totalCalls: number;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  onForceUpdate: () => void;
  onCallSelect: (call: Call) => void;
  isSuperuser: boolean;
  searchTerm: string;
  onSearchChange: (searchTerm: string) => void;
  summary: Summary | null;
  isLoading: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const TranscriptPreview = styled('span')({
  cursor: 'pointer',
  color: 'blue',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
});

const CallTable: React.FC<CallTableProps> = ({
  calls,
  totalCalls,
  currentPage,
  totalPages,
  onPageChange,
  onForceUpdate,
  onCallSelect,
  isSuperuser,
  searchTerm,
  onSearchChange,
  isLoading,
}) => {
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);
  const [openTranscript, setOpenTranscript] = useState(false);
  const [selectedTranscript, setSelectedTranscript] = useState('');

  const handleOpenTranscript = (transcript: string) => {
    setSelectedTranscript(transcript);
    setOpenTranscript(true);
  };

  const handleCloseTranscript = () => {
    setOpenTranscript(false);
  };

  const getActionStatus = (call: Call) => {
    if (!call.call_actions || call.call_actions.length === 0) {
      return 'none';
    }
    const hasFailedAction = call.call_actions.some(action => action.action_status === 'error');
    return hasFailedAction ? 'failed' : 'success';
  };

  const countActions = (call: Call): number => {
    if (!call.call_actions) return 0;

    const uniqueScreenshots = new Set();
    call.call_actions.forEach(action => {
      if (action.action_output_data) {
        try {
          const outputData = JSON.parse(action.action_output_data);
          if (outputData.screenshots) {
            outputData.screenshots.forEach((screenshot: string) => {
              const screenshotName = screenshot.split('/').pop();
              uniqueScreenshots.add(screenshotName);
            });
          }
        } catch (error) {
          console.error('Error parsing action output data:', error);
        }
      }
    });

    return uniqueScreenshots.size;
  };

  const handleOpenZoom = (imageSrc: string) => {
    setZoomedImage(imageSrc);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  const toggleRow = (callId: string) => {
    setOpenRows(prevState => ({ ...prevState, [callId]: !prevState[callId] }));
  };

  const formatCurrency = (amount: number | null | undefined) => {
    if (amount === null || amount === undefined) return 'N/A';
    return `$${amount.toFixed(2)}`;
  };

  const formatDuration = (start: number | null | undefined, end: number | null | undefined) => {
    if (start === null || start === undefined || end === null || end === undefined) return 'N/A';
    const duration = (end - start) / 1000;
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}m ${seconds}s`;
  };

  const formatAssetPath = (path: string) => {
    return path.replace('/home/basedgpt/platform/deployments/envoi/bumble-auto/bumble/', '/assets/');
  };

  const renderCallActions = (actions: CallAction[]) => (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <Box margin={2}>
            <Typography variant="h6" gutterBottom component="div">
              Call Actions
            </Typography>
            {actions.map((action, index) => (
              <Accordion key={action.action_id} elevation={3} sx={{ mb: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Box display="flex" alignItems="center" width="100%">
                    <Typography sx={{ flexGrow: 1, fontWeight: 'bold' }}>{action.action_type}</Typography>
                    <Chip
                      icon={action.action_status === 'error' ? <ErrorOutlineIcon /> : <CheckCircleOutlineIcon />}
                      label={action.action_status}
                      color={action.action_status === 'error' ? 'error' : 'success'}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="caption">
                      {new Date(action.action_timestamp * 1000).toLocaleString()}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper elevation={2} sx={{ p: 2, bgcolor: 'grey.100' }}>
                        <Typography variant="subtitle2" gutterBottom>Status Line:</Typography>
                        <Typography variant="body2">{action.status_line}</Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <CodeIcon sx={{ mr: 1 }} />
                          <Typography variant="subtitle2">Input Data:</Typography>
                        </Box>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Value</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {JSON.parse(action.action_input_data).map((item: string, idx: number) => (
                              <TableRow key={idx}>
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell>{item}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Paper elevation={2} sx={{ p: 2, height: '100%' }}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <CodeIcon sx={{ mr: 1 }} />
                          <Typography variant="subtitle2">Output Data:</Typography>
                        </Box>
                        {action.action_output_data && (() => {
                          try {
                            const outputData = JSON.parse(action.action_output_data);
                            return (
                              <Box>
                                {outputData.screenshots && (
                                  <Box mb={2}>
                                    <Box display="flex" alignItems="center" mb={1}>
                                      <ImageIcon sx={{ mr: 1 }} />
                                      <Typography variant="subtitle2">Screenshots:</Typography>
                                    </Box>
                                    <Grid container spacing={1}>
                                      {outputData.screenshots.map((screenshot: string, idx: number) => (
                                        <Grid item xs={6} sm={4} md={3} key={idx}>
                                          <Box position="relative">
                                            <img 
                                              src={formatAssetPath(screenshot)} 
                                              alt={`Screenshot ${idx + 1}`} 
                                              style={{ width: '100%', height: 'auto', borderRadius: '4px', cursor: 'pointer' }} 
                                              onClick={() => handleOpenZoom(formatAssetPath(screenshot))}
                                              onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.onerror = null;
                                                target.src = '/placeholder-image.png';
                                              }}
                                            />
                                            <IconButton
                                              size="small"
                                              style={{
                                                position: 'absolute',
                                                right: '5px',
                                                top: '5px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                              }}
                                              onClick={() => handleOpenZoom(formatAssetPath(screenshot))}
                                            >
                                              <ZoomInIcon fontSize="small" />
                                            </IconButton>
                                          </Box>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Box>
                                )}
                                {outputData.videoPath && (
                                  <Box mt={2}>
                                    <Box display="flex" alignItems="center" mb={1}>
                                      <VideoLibraryIcon sx={{ mr: 1 }} />
                                      <Typography variant="subtitle2">Video:</Typography>
                                    </Box>
                                    <video 
                                      controls 
                                      width="100%" 
                                      style={{ borderRadius: '4px' }}
                                      poster="/video-placeholder.png"
                                    >
                                      <source src={formatAssetPath(outputData.videoPath)} type="video/webm" />
                                      Your browser does not support the video tag.
                                    </video>
                                  </Box>
                                )}
                              </Box>
                            );
                          } catch (error) {
                            console.error('Error parsing output data:', error);
                            return <Typography color="error">Error parsing output data: {(error as Error).message}</Typography>;
                          }
                        })()}
                      </Paper>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Transcript</StyledTableCell>
                <StyledTableCell>Call ID</StyledTableCell>
                <StyledTableCell>{isSuperuser ? 'Agent ID' : 'Agent Name'}</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Start Time</StyledTableCell>
                <StyledTableCell>Duration</StyledTableCell>
                <StyledTableCell>Disconnection Reason</StyledTableCell>
                <StyledTableCell>Call Type</StyledTableCell>
                {isSuperuser && (
                  <>
                    <StyledTableCell>Retell Cost</StyledTableCell>
                    <StyledTableCell>OpenAI Cost</StyledTableCell>
                    <StyledTableCell>Combined Cost</StyledTableCell>
                    <StyledTableCell>LLM Model</StyledTableCell>
                    <StyledTableCell>Voice Provider</StyledTableCell>
                  </>
                )}
                <StyledTableCell>Actions</StyledTableCell>
                <StyledTableCell>Recording</StyledTableCell>
                {isSuperuser && <StyledTableCell>Log</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {calls.map((call) => (
                <React.Fragment key={call.call_id}>
                  <StyledTableRow 
                    onClick={() => onCallSelect(call)} 
                    style={{ cursor: 'pointer' }}
                    hover
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleRow(call.call_id);
                        }}
                      >
                        {openRows[call.call_id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Click to view full transcript">
                        <TranscriptPreview
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenTranscript(call.transcript || 'No transcript available');
                          }}
                        >
                          {call.transcript ? call.transcript.substring(0, 50) + '...' : 'N/A'}
                        </TranscriptPreview>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{call.display_id}</TableCell>
                    <TableCell>
                      {isSuperuser 
                        ? call.agent_id 
                        : call.agent_id === 'agent_b03f5f6bf9f3aa012934e7962a' 
                          ? 'Bumble Agent' 
                          : 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={call.call_status} 
                        color={call.call_status === 'completed' ? 'success' : 'warning'} 
                        size="small" 
                      />
                    </TableCell>
                    <TableCell>{new Date(call.start_timestamp || 0).toLocaleString()}</TableCell>
                    <TableCell>{formatDuration(call.start_timestamp, call.end_timestamp)}</TableCell>
                    <TableCell>{call.disconnection_reason || 'N/A'}</TableCell>
                    <TableCell>{call.call_type}</TableCell>
                    {isSuperuser && (
                      <>
                        <TableCell>{formatCurrency((call.call_cost?.combined_cost || 0) / 100)}</TableCell>
                        <TableCell>{formatCurrency(call.estimated_openai_cost)}</TableCell>
                        <TableCell>{formatCurrency(((call.call_cost?.combined_cost || 0) / 100) + (call.estimated_openai_cost || 0))}</TableCell>
                        <TableCell>{call.cost_metadata?.llm_model}</TableCell>
                        <TableCell>{call.cost_metadata?.voice_provider}</TableCell>
                      </>
                    )}
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {(() => {
                          const actionCount = countActions(call);
                          const actionStatus = getActionStatus(call);
                          
                          let StatusIcon;
                          let iconColor;
                          
                          switch (actionStatus) {
                            case 'success':
                              StatusIcon = CheckCircleIcon;
                              iconColor = 'success.main';
                              break;
                            case 'failed':
                              StatusIcon = ErrorIcon;
                              iconColor = 'error.main';
                              break;
                            default:
                              StatusIcon = HelpOutlineIcon;
                              iconColor = 'action.disabled';
                          }
    
                          return (
                            <>
                              {actionCount > 0 && (
                                <Tooltip title={actionStatus === 'failed' ? 'Some actions failed' : 'All actions successful'}>
                                  <StatusIcon sx={{ color: iconColor, mr: 1 }} />
                                </Tooltip>
                              )}
                              {actionCount}
                            </>
                          );
                        })()}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {call.recording_url ? (
                        <Tooltip title="Listen to recording">
                          <Link href={call.recording_url} target="_blank" rel="noopener noreferrer">
                            Listen
                          </Link>
                        </Tooltip>
                      ) : 'N/A'}
                    </TableCell>
                    {isSuperuser && (
                      <TableCell>
                        {call.public_log_url ? (
                          <Tooltip title="View log">
                            <Link href={call.public_log_url} target="_blank" rel="noopener noreferrer">
                              View
                            </Link>
                          </Tooltip>
                        ) : 'N/A'}
                      </TableCell>
                    )}
                  </StyledTableRow>
                  {openRows[call.call_id] && call.call_actions && call.call_actions.length > 0 && renderCallActions(call.call_actions)}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        component="div"
        count={totalCalls}
        page={currentPage - 1}
        onPageChange={(event, newPage) => onPageChange(newPage + 1)}
        rowsPerPage={calls.length}
        rowsPerPageOptions={[calls.length]}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`}
      />
      <Dialog
        open={openTranscript}
        onClose={handleCloseTranscript}
        aria-labelledby="transcript-dialog-title"
        aria-describedby="transcript-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 15,
            padding: '20px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
          }
        }}
      >
        <DialogTitle id="transcript-dialog-title" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '10px' }}>
          <Typography variant="h5" component="div" style={{ fontWeight: 'bold' }}>
            Call Transcript
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: '20px' }}>
          <Typography
            id="transcript-dialog-description"
            style={{ 
              whiteSpace: 'pre-wrap', 
              fontFamily: 'Roboto, sans-serif',
              lineHeight: 1.6,
              fontSize: '16px'
            }}
          >
            {selectedTranscript}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button 
            onClick={handleCloseTranscript} 
            color="primary" 
            variant="contained"
            style={{ borderRadius: 20, textTransform: 'none' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={!!zoomedImage}
        onClose={handleCloseZoom}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!zoomedImage}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: '1000px',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseZoom}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <img 
              src={zoomedImage || ''} 
              alt="Zoomed screenshot" 
              style={{ width: '100%', height: 'auto' }} 
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CallTable;