import React from 'react';
import {
  Dialog, DialogTitle, DialogContent, Typography, Box, IconButton,
  Grid, Paper, Accordion, AccordionSummary, AccordionDetails,
  Chip, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Avatar
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Call } from '../types/Call';
import { isSuper } from '@babel/types';

interface CallDetailsProps {
  call: Call | null;
  onClose: () => void;
  llm_latency?: Record<string, unknown>;
  llm_websocket_network_rtt_latency?: Record<string, unknown>;
  e2e_latency?: Record<string, unknown>;
  isSuperUser: boolean
}

const CallDetails: React.FC<CallDetailsProps> = ({ call, onClose, isSuperUser }) => {
  if (!call) return null;

  const formatDate = (timestamp: number | undefined) => {
    return timestamp ? new Date(timestamp).toLocaleString() : 'N/A';
  };

  const formatDuration = (start?: number, end?: number) => {
    if (!start || !end) return 'N/A';
    const duration = (end - start) / 1000; // Convert to seconds
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}m ${seconds}s`;
  };

  const formatCurrency = (amount?: number | null) => {
    if (amount === null || amount === undefined) return 'N/A';
    return `$${amount.toFixed(2)}`;
  };

  const renderLatencyInfo = (latency: Record<string, unknown>) => {
    if (!latency || typeof latency !== 'object') {
      return <Typography>No latency data available</Typography>;
    }
    return (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Metric</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(latency).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">{key}</TableCell>
                  <TableCell align="right">{String(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    };

    const renderTranscript = (transcript: string | any[]) => {
      if (!transcript) return <Typography>No transcript available</Typography>;
    
      if (typeof transcript === 'string') {
        // Split the string transcript into turns
        const turns = transcript.split(/(?=User:|Agent:)/g);
        return (
          <Box sx={{ maxHeight: '500px', overflowY: 'auto', p: 2, bgcolor: '#f5f5f5' }}>
            {turns.map((turn, index) => {
              const [speaker, ...contentArr] = turn.trim().split(':');
              const content = contentArr.join(':').trim();
              const isAgent = speaker.toLowerCase() === 'agent';
              return (
                <Box key={index} sx={{ display: 'flex', mb: 2, flexDirection: isAgent ? 'row' : 'row-reverse' }}>
                  <Avatar sx={{ bgcolor: isAgent ? '#1976d2' : '#388e3c', mr: isAgent ? 1 : 0, ml: isAgent ? 0 : 1 }}>
                    {isAgent ? <SmartToyIcon /> : <PersonIcon />}
                  </Avatar>
                  <Paper elevation={1} sx={{ 
                    p: 2, 
                    maxWidth: '70%', 
                    bgcolor: isAgent ? '#e3f2fd' : '#fff',
                    borderRadius: isAgent ? '20px 20px 20px 5px' : '20px 20px 5px 20px'
                  }}>
                    <Typography variant="body1">{content}</Typography>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        );
      }
    
      if (Array.isArray(transcript)) {
        return (
          <Box sx={{ maxHeight: '500px', overflowY: 'auto', p: 2, bgcolor: '#f5f5f5' }}>
            {transcript.map((item, index) => {
              const isAgent = item.role.toLowerCase() === 'agent';
              return (
                <Box key={index} sx={{ display: 'flex', mb: 2, flexDirection: isAgent ? 'row' : 'row-reverse' }}>
                  <Avatar sx={{ bgcolor: isAgent ? '#1976d2' : '#388e3c', mr: isAgent ? 1 : 0, ml: isAgent ? 0 : 1 }}>
                    {isAgent ? <SmartToyIcon /> : <PersonIcon />}
                  </Avatar>
                  <Paper elevation={1} sx={{ 
                    p: 2, 
                    maxWidth: '70%', 
                    bgcolor: isAgent ? '#e3f2fd' : '#fff',
                    borderRadius: isAgent ? '20px 20px 20px 5px' : '20px 20px 5px 20px'
                  }}>
                    <Typography variant="body1">{item.content}</Typography>
                  </Paper>
                </Box>
              );
            })}
          </Box>
        );
      }
    
      return <Typography>Invalid transcript format</Typography>;
    };

  return (
    <Dialog 
      open={!!call} 
      onClose={onClose} 
      fullScreen
      PaperProps={{ 
        sx: { 
          bgcolor: 'background.default',
          backgroundImage: 'none',
        } 
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">Call Details</Typography>
          <IconButton onClick={onClose} edge="end" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ p: 2, overflowY: 'auto' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h5" gutterBottom>General Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call ID</Typography>
                    <Typography variant="body1">{call.call_id}</Typography>
                  </Grid>
                  {isSuperUser && 
                  <Grid item xs={12} sm={6} md={4}>
                   <Typography variant="subtitle2" color="textSecondary">Agent ID</Typography>
                    <Typography variant="body1">{call.agent_id || 'N/A'}</Typography>
                  </Grid>}
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call Status</Typography>
                    <Chip label={call.call_status || 'N/A'} color="primary" size="small" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Call Type</Typography>
                    <Typography variant="body1">{call.call_type || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Start Time</Typography>
                    <Typography variant="body1">{formatDate(call.start_timestamp)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">End Time</Typography>
                    <Typography variant="body1">{formatDate(call.end_timestamp)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Duration</Typography>
                    <Typography variant="body1">{formatDuration(call.start_timestamp, call.end_timestamp)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="subtitle2" color="textSecondary">Disconnection Reason</Typography>
                    <Typography variant="body1">{call.disconnection_reason || 'N/A'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {isSuperUser &&
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%' }}>
                <Typography variant="h5" gutterBottom>Cost Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Retell Cost</Typography>
                    <Typography variant="h6">{formatCurrency(call.call_cost?.combined_cost)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">OpenAI Cost</Typography>
                    <Typography variant="h6">{formatCurrency(call.estimated_openai_cost)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Combined Cost</Typography>
                    <Typography variant="h6">{formatCurrency((call.call_cost?.combined_cost || 0) + (call.estimated_openai_cost || 0))}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">LLM Model</Typography>
                    <Typography variant="body1">{call.cost_metadata?.llm_model || 'N/A'}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Voice Provider</Typography>
                    <Typography variant="body1">{call.cost_metadata?.voice_provider || 'N/A'}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>}

            {call.call_analysis && (
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%' }}>
                  <Typography variant="h5" gutterBottom>Call Analysis</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">Task Completion</Typography>
                      <Typography variant="body1">{call.call_analysis.agent_task_completion_rating || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">Call Successful</Typography>
                      <Chip label={call.call_analysis.call_successful ? 'Yes' : 'No'} color={call.call_analysis.call_successful ? 'success' : 'error'} size="small" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">In Voicemail</Typography>
                      <Chip label={call.call_analysis.in_voicemail ? 'Yes' : 'No'} color={call.call_analysis.in_voicemail ? 'warning' : 'success'} size="small" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">User Sentiment</Typography>
                      <Typography variant="body1">{call.call_analysis.user_sentiment || 'N/A'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">Call Summary</Typography>
                      <Typography variant="body1">{call.call_analysis.call_summary || 'N/A'}</Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}

<Grid item xs={12}>
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">Transcript</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {renderTranscript(call.transcript || call.transcript_object || '')}
    </AccordionDetails>
  </Accordion>
</Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Latency Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
  <Typography variant="subtitle1" gutterBottom>LLM Latency</Typography>
  {renderLatencyInfo(call.llm_latency || {})}
  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>LLM Websocket Network RTT Latency</Typography>
  {renderLatencyInfo(call.llm_websocket_network_rtt_latency || {})}
  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>E2E Latency</Typography>
  {renderLatencyInfo(call.e2e_latency || {})}
</AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h5" gutterBottom>Links</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color="textSecondary">Recording</Typography>
                    {call.recording_url ? (
                      <a href={call.recording_url} target="_blank" rel="noopener noreferrer">Listen</a>
                    ) : 'N/A'}
                  </Grid>
                  {isSuperUser&& <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="textSecondary">Public Log</Typography>
                    {call.public_log_url ? (
                      <a href={call.public_log_url} target="_blank" rel="noopener noreferrer">View</a>
                    ) : 'N/A'}
                  </Grid> }
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                 {isSuperUser && <Typography variant="h6">Raw Call Data</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                  <Paper elevation={3} sx={{ p: 2, maxHeight: '500px', overflow: 'auto' }}>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                      {JSON.stringify(call, null, 2)}
                    </pre>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CallDetails;