import axios from 'axios';
import { Call } from './types/Call';
import { Summary } from './types';

const API_URL = process.env.REACT_APP_BACKEND_URL || '/api';

interface CallsResponse {
  calls: Call[];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

class DataManager {
  private calls: Call[] = [];
  private userEmail: string | null = null;
  private isSuperuser: boolean = false;
  private currentPage: number = 1;
  private limit: number = 50;
  private totalPages: number = 1;

  public setUserEmail(email: string) {
    this.userEmail = email;
  }
  public getUserEmail(): string | null {
    return this.userEmail;
  }
  public setIsSuperuser(isSuperuser: boolean) {
    this.isSuperuser = isSuperuser;
  }

  public getIsSuperuser(): boolean {
    return this.isSuperuser;
  }

  private getHeaders() {
    return {
      'user-email': this.userEmail || '',
    };
  }

  public async getCalls(page: number = 1, searchTerm: string = ''): Promise<CallsResponse> {
    if (!this.userEmail) {
      console.error('User email not set');
      return { calls: [], total: 0, page: 1, limit: this.limit, totalPages: 1 };
    }
  
    try {
      const response = await axios.get<CallsResponse>(`${API_URL}/calls`, {
        headers: this.getHeaders(),
        params: { page, limit: this.limit, search: searchTerm }
      });
      this.calls = response.data.calls;
      this.currentPage = response.data.page;
      this.totalPages = response.data.totalPages;
      return response.data;
    } catch (error) {
      console.error('Error fetching calls:', error);
      return { calls: [], total: 0, page: 1, limit: this.limit, totalPages: 1 };
    }
  }
  public async getCallSummary(): Promise<Summary | null> {
    if (!this.userEmail) {
      console.error('User email not set');
      return null;
    }

    try {
      const response = await axios.get(`${API_URL}/call-summary`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching call summary:', error);
      return null;
    }
  }

  public async getAgentAnalytics(): Promise<any[]> {
    if (!this.userEmail) {
      console.error('User email not set');
      return [];
    }

    try {
      const response = await axios.get(`${API_URL}/agent-analytics`, {
        headers: this.getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching agent analytics:', error);
      return [];
    }
  }

  public async forceUpdate(): Promise<void> {
    if (!this.userEmail) {
      console.error('User email not set');
      return;
    }

    try {
      await axios.post(`${API_URL}/update-calls`, {}, {
        headers: this.getHeaders(),
      });
      await this.getCalls(1); // Reset to first page after update
    } catch (error) {
      console.error('Error updating calls:', error);
    }
  }

  public getCurrentPage(): number {
    return this.currentPage;
  }

  public getTotalPages(): number {
    return this.totalPages;
  }

  public getLimit(): number {
    return this.limit;
  }
}

export const dataManager = new DataManager();